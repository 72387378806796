import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dialog-footer pt-1" }


import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'EditCancelDialog',
  props: {
    "modelValue": {},
    "modelModifiers": {},
    "fn": {},
    "fnModifiers": {},
  },
  emits: ["update:modelValue", "update:fn"],
  setup(__props) {

const { t } = useI18n();
const modelValue = _useModel(__props, "modelValue");
const fn = _useModel(__props, 'fn');
const router = useRouter();

if (fn.value === undefined) {
  fn.value = () => router.back();
}

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: modelValue.value,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((modelValue).value = $event)),
    title: _unref(t)('ExitDialog.title'),
    width: "500",
    "align-center": ""
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_button, {
          link: "",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (modelValue.value = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('ExitDialog.continueEditing')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          link: "",
          onClick: fn.value
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('ExitDialog.exitWithoutSaving')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("span", null, _toDisplayString(_unref(t)('ExitDialog.confirmationText')), 1)
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}
}

})