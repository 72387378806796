import {
  createStore,
  createLogger,
  GetterTree,
  MutationTree,
  ActionTree,
} from "vuex";
import auth from "./modules/auth.module";
import { SettingList } from "@/models/settings";
import { BusinessProcess } from "@/models/bp";
import { Crm } from "@/models/crm";
import { Option } from "@/models/options";
import { ChatCo } from "@/models/chatCo";
import { Folder } from "@/models/folder";
import { ChatTask } from "@/models/chatTask";

const plugins = [];

if (process.env.NODE_ENV === "development") {
  plugins.push(createLogger());
}

class State {
  editFlag: string[] = [];
  chatForDelete: number[] = [];
  chatCoForDelete: number[] = [];
  chatTaskForDelete: number[] = [];
  chatErrors: string[] = [];
  chatCo: ChatCo[] = [];
  chatTask: ChatTask[] = [];
  currentFolderName = "";
  folder: Folder = {
    id: 0,
    uf_domain_id: 0,
    uf_name: "",
    uf_count_chats: 0,
    uf_count_errors: 0,
    uf_create_b24_chat: 0,
    uf_create_b24_chat_users: "",
    uf_crm: "",
    uf_bp: "",
  };
  botTokenModal = false;
  bitrixBotModal = false;
  SettingList: SettingList = {};
  bpList: BusinessProcess[] = [];
  bpForDelete: number[] = [];
  bpTotal = 0;
  crmList: Crm[] = [];
  crmForDelete: number[] = [];
  crmTotal = 0;
  tgOptions: Option[] = [];
}

export const errText = (code: number): string => {
  switch (code) {
    case 0: {
      return "";
    }
    case 1: {
      return "Токен бота Telegram указан с ошибкой или он был отозван создателем бота.";
    }
    default: {
      return "Unknown error";
    }
  }
};

export default createStore({
  state() {
    return new State();
  },
  mutations: <MutationTree<State>>{
    setBotTokenModal(state, value: boolean): void {
      state.botTokenModal = value;
    },
    setBitrixBotModal(state, value: boolean): void {
      state.bitrixBotModal = value;
    },
    setSettingsState(state, value: SettingList): void {
      Object.assign(state.SettingList, value);
    },
    setBpTotal(state, value: number): void {
      state.bpTotal = value;
    },
    incBpTotal(state, value: number): void {
      state.bpTotal += value;
    },
    incCrmTotal(state, value: number): void {
      state.crmTotal += value;
    },
    setCrmTotal(state, value: number): void {
      state.crmTotal = value;
    },
    addEditFlag(state, value: string): void {
      if (!state.editFlag.find((change) => value === change)) {
        state.editFlag.push(value);
      }
    },
    addBpList(state, value: BusinessProcess[]): void {
      const existingIds = new Set(state.bpList.map((bp) => bp.id));
      const newItems = value.filter((bp) => !existingIds.has(bp.id));
      state.bpList = [...state.bpList, ...newItems];
    },
    addTgChatOption(state, value: Option[]): void {
      state.tgOptions = value;
    },
    addChatCoList(state, value: ChatCo[]): void {
      const existingIds = new Set(state.chatCo.map((chat) => chat.id));
      const newItems = value.filter((chat) => !existingIds.has(chat.id));
      state.chatCo = [...state.chatCo, ...newItems];
    },
    addChatTaskList(state, value: ChatTask[]): void {
      value = value.map((item) => {
        item.users = item.uf_users.split(",");
        return item;
      });
      state.chatTask = [...state.chatTask, ...value];
    },
    addChatErrors(state, value: string[]): void {
      state.chatErrors = value;
    },
    setFolder(state, value: Folder): void {
      state.folder = value;
    },
    addChatCo(state, value: ChatCo): void {
      state.chatCo = [...state.chatCo, value];
      if (!state.editFlag.find((change) => "chatCo" === change)) {
        state.editFlag.push("newChatCo");
      }
    },
    addChatTask(state, value: ChatTask): void {
      state.chatTask = [...state.chatTask, value];
      if (!state.editFlag.find((change) => "chatTask" === change)) {
        state.editFlag.push("newChatTask");
      }
    },
    updateChatCo(state, { oldVal, newVal }): void {
      const index = state.chatCo.findIndex((change) => change.id === oldVal.id);
      if (index > -1) {
        state.chatCo[index] = newVal;
      }
    },
    updateChatTask(state, { oldVal, newVal }): void {
      const index = state.chatTask.findIndex(
        (change) => change.id === oldVal.id
      );
      if (index > -1) {
        newVal.users = newVal.uf_users.split(",");
        state.chatTask[index] = newVal;
      }
    },
    removeChatCo(state, id: number): void {
      state.chatCo = state.chatCo.filter((change) => id !== change.id);
      if (!state.chatCo.find((change) => change.id < 0)) {
        state.editFlag = state.editFlag.filter(
          (change) => "newChatCo" !== change
        );
      }
    },
    removeChatTask(state, id: number): void {
      state.chatTask = state.chatTask.filter((change) => id !== change.id);
      if (!state.chatTask.find((change) => change.id < 0)) {
        state.editFlag = state.editFlag.filter(
          (change) => "newChatTask" !== change
        );
      }
    },
    addCrmList(state, value: Crm[]): void {
      const existingIds = new Set(state.crmList.map(crm => crm.id));
      const newItems = value.filter(crm => !existingIds.has(crm.id));
      state.crmList = [...state.crmList, ...newItems];
    },
    addBpForDelete(state, id: number): void {
      if (!state.bpForDelete.find((change) => id === change)) {
        state.bpForDelete.push(id);
      }
      if (!state.editFlag.find((change) => "bp" === change)) {
        state.editFlag.push("bp");
      }
      state.bpList = state.bpList.filter((change) => id !== change.id);
    },
    addCrmForDelete(state, id: number): void {
      if (!state.crmForDelete.find((change) => id === change)) {
        state.crmForDelete.push(id);
      }
      if (!state.editFlag.find((change) => "crm" === change)) {
        state.editFlag.push("crm");
      }
      state.crmList = state.crmList.filter((change) => id !== change.id);
    },
    addChatForDelete(state, id: number): void {
      if (!state.chatForDelete.find((change) => id === change)) {
        state.chatForDelete.push(id);
      }
      if (!state.editFlag.find((change) => "chat" === change)) {
        state.editFlag.push("chat");
      }
    },
    addChatCoForDelete(state, id: number): void {
      if (!state.chatCoForDelete.find((change) => id === change)) {
        state.chatCoForDelete.push(id);
      }
      if (!state.editFlag.find((change) => "chatCo" === change)) {
        state.editFlag.push("chatCo");
      }
    },
    addChatTaskForDelete(state, id: number): void {
      if (!state.chatTaskForDelete.find((change) => id === change)) {
        state.chatTaskForDelete.push(id);
      }
      if (!state.editFlag.find((change) => "chatTask" === change)) {
        state.editFlag.push("chatTask");
      }
    },
    deleteEditFlag(state, value: string): void {
      state.editFlag = state.editFlag.filter((change) => value !== change);
    },
    deleteTgChatOption(state, value: string): void {
      state.tgOptions = state.tgOptions.filter(
        (change) => change.value !== value
      );
    },
    saveState(state): void {
      state.editFlag = [];
      state.chatForDelete = [];
      state.chatCoForDelete = [];
      state.chatTaskForDelete = [];
      state.bpTotal -= state.bpForDelete.length;
      state.bpForDelete = [];
      state.crmTotal -= state.crmForDelete.length;
      state.crmForDelete = [];
      state.chatErrors = [];
    },
    clear(state): void {
      Object.assign(state, new State());
    },
    setCurrentFolderName(state, value: string): void {
      state.currentFolderName = value;
    },
  },
  actions: <ActionTree<State, unknown>>{},
  getters: <GetterTree<State, unknown>>{
    getEditFlags(state): string[] {
      return state.editFlag;
    },
    getChatErrrors(state): string[] {
      return state.chatErrors;
    },
    getFolder(state): Folder {
      return state.folder;
    },
    getTgChatOptions(state): Option[] {
      return state.tgOptions;
    },
    getChatCo(state): ChatCo[] {
      return state.chatCo;
    },
    getChatTask(state): ChatTask[] {
      return state.chatTask;
    },
    getChatForDelete(state): number[] {
      return state.chatForDelete;
    },
    getChatCoForDelete(state): number[] {
      return state.chatCoForDelete;
    },
    getChatTaskForDelete(state): number[] {
      return state.chatTaskForDelete;
    },
    getEditFlag: (state) => (flag: string) => {
      return state.editFlag.find((val) => val === flag);
    },
    getEditInList:
      (state) =>
      (flag: string[]): boolean => {
        return state.editFlag.some((val) => flag.includes(val));
      },
    getCurrentFolderName(state): string {
      return state.currentFolderName;
    },
    getBotTokenModal(state): boolean {
      return state.botTokenModal;
    },
    getBitrixBotModal(state): boolean {
      return state.bitrixBotModal;
    },
    getSettingList(state): SettingList {
      return state.SettingList;
    },
    getBpList(state): BusinessProcess[] {
      return state.bpList;
    },
    getCrmList(state): Crm[] {
      return state.crmList;
    },
    getBpForDelete(state): number[] {
      return state.bpForDelete;
    },
    getCrmForDelete(state): number[] {
      return state.crmForDelete;
    },
    getBpTotal(state): number {
      return state.bpTotal;
    },
    getCrmTotal(state): number {
      return state.crmTotal;
    },
  },
  modules: {
    auth,
  },
  plugins,
});
