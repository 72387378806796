import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "secondary pt-1",
  style: {"text-align":"center"}
}
const _hoisted_2 = { class: "custom-style pt-1" }
const _hoisted_3 = { class: "secondary" }
const _hoisted_4 = { class: "tooltip-text" }
const _hoisted_5 = {
  key: 0,
  class: "custom-style pt-1"
}
const _hoisted_6 = { class: "secondary" }
const _hoisted_7 = { class: "tooltip-text" }

import { getSettingList, updateSetting } from '@/api/settings';
import { SettingList } from '@/models/settings';
import { AxiosResponse } from 'axios';
import { ElMessage } from 'element-plus';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { errText } from '@/store';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'BotTokenDialog',
  setup(__props) {

const { t } = useI18n();
const store = useStore();
const radio1 = ref('bx24tgbot');
const error = ref();
const input = ref();
const bSave = ref();
const loading = ref(false);

const loadData = async () => {
  loading.value = true;
  await getSettingList()
    .then((response: AxiosResponse<SettingList>) => {
      radio1.value = response.data.selectType ?? '';
      input.value = response.data.tgToken;
      loading.value = false;
    })
    .catch((error) => {
      console.error(error);
      ElMessage({
        message: t('BotTokenDialog.errorFetchingSettings'),
        type: 'error',
        duration: 3000,
      });
    });
};

const save = async () => {
  loading.value = true;
  if (radio1.value === 'bx24tgbot') {
    input.value = '';
  }
  const req: SettingList = {
    tgToken: input.value,
    selectType: radio1.value,
  };
  await updateSetting(req)
    .then((response) => {
      if (response.data.code) {
        error.value = errText(response.data.code);
      } else {
        error.value = null;
        store.commit('setBotTokenModal', false);
        store.commit('auth/setDomainError', 0);
        setTimeout(() => {
          ElMessage({
            message: t('BotTokenDialog.successMessage'),
            type: 'success',
            duration: 3000,
          });
        }, 500);
      }
      loading.value = false;
    })
    .catch((error) => {
      console.error(error);
      ElMessage({
        message: t('BotTokenDialog.errorUpdatingSettings'),
        type: 'error',
        duration: 3000,
      });
    });
};

const st = computed(() => store.getters['getBotTokenModal']);
const modelValue = ref(false);

const close = () => {
  store.commit('setBotTokenModal', false);
};

watch(st, (newX) => {
  modelValue.value = newX;
});

watch(input, (newX) => {
  if (newX.length == 0 && radio1.value === 'self' && !bSave.value) {
    bSave.value = true;
  } else if (bSave.value && newX.length > 0) {
    bSave.value = false;
  }
});

watch(radio1, (newX) => {
  if (input.value.length == 0 && newX === 'self' && !bSave.value) {
    bSave.value = true;
  } else if (bSave.value && newX === 'bx24tgbot') {
    bSave.value = false;
  }
});

watch(modelValue, (newX) => {
  if (newX) {
    loadData();
  } else {
    bSave.value = null;
    error.value = null;
  }
  store.commit('setBotTokenModal', newX);
});

return (_ctx: any,_cache: any) => {
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_question_filled = _resolveComponent("question-filled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: modelValue.value,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((modelValue).value = $event)),
    "show-close": false,
    width: "500"
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("h4", null, _toDisplayString(_unref(t)('BotTokenDialog.title')), 1),
        _createElementVNode("div", _hoisted_1, _toDisplayString(_unref(t)('BotTokenDialog.description')), 1)
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_divider),
      _withDirectives((_openBlock(), _createElementBlock("div", null, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(t)('BotTokenDialog.selectBot')), 1),
            _createVNode(_component_el_tooltip, {
              effect: "light",
              placement: "top"
            }, {
              content: _withCtx(() => [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(t)('BotTokenDialog.tooltips.selectBot')), 1)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { class: "tooltip" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_question_filled)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_el_radio_group, {
            modelValue: radio1.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((radio1).value = $event)),
            style: {"padding-left":"5px","padding-top":"5px"}
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_component_el_radio, { value: "bx24tgbot" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('BotTokenDialog.useStandard')), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", null, [
                _createVNode(_component_el_radio, { value: "self" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('BotTokenDialog.useCustom')), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        (radio1.value == 'self')
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", null, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(t)('BotTokenDialog.telegramBotToken')), 1),
                _createVNode(_component_el_tooltip, {
                  effect: "light",
                  placement: "top"
                }, {
                  content: _withCtx(() => [
                    _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(t)('BotTokenDialog.tooltips.telegramBotToken')), 1)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_el_icon, { class: "tooltip" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_question_filled)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _createVNode(_component_el_input, {
                class: _normalizeClass(error.value ? 'red-border' : ''),
                size: "large",
                modelValue: input.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((input).value = $event)),
                placeholder: _unref(t)('BotTokenDialog.enterTokenPlaceholder'),
                style: {"width":"100%","padding":"1rem 0"}
              }, null, 8, ["class", "modelValue", "placeholder"]),
              _createElementVNode("small", null, _toDisplayString(error.value), 1)
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_el_divider),
        _createVNode(_component_el_row, {
          class: "p-7",
          style: {"display":"flex","flex-direction":"row-reverse"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              disabled: bSave.value,
              style: {"height":"40px"},
              class: "primary",
              onClick: save,
              type: "primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)('BotTokenDialog.save')), 1)
              ]),
              _: 1
            }, 8, ["disabled"]),
            _createVNode(_component_el_button, {
              style: {"height":"40px","margin-right":"1rem"},
              onClick: close,
              link: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)('BotTokenDialog.cancel')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])), [
        [_directive_loading, loading.value]
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})