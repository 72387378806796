<template>
  <div class="p-0-05"><el-divider /></div>
  <el-row v-for="(link) in contacts" :key="link.link">
    <el-col :span="23" class="pl-1 mh40 m0">
      <div class="m0">{{ link.title }}</div>
      <div v-if="link.subtitle" class="secondary">{{ link.subtitle }}</div>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Link } from '@/models/link';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const contacts: Link[] = [
      {
        title: t('Contacts.email'),
      },
    ];

    return {
      contacts,
    };
  },
});
</script>

<style scoped></style>