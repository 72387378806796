import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"text-decoration":"underline"} }

import { computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'BotTokenError',
  setup(__props) {

const { t } = useI18n();
const store = useStore();
const error = computed(() => store.getters['auth/error']);

const open = () => {
  store.commit('setBotTokenModal', true);
};

return (_ctx: any,_cache: any) => {
  const _component_el_alert = _resolveComponent("el-alert")!

  return (error.value)
    ? (_openBlock(), _createBlock(_component_el_alert, {
        key: 0,
        onClick: open,
        style: {"cursor":"pointer","font-weight":"500"},
        class: "card",
        title: _unref(t)('BotErrorAlert.title'),
        closable: false,
        type: "error",
        "show-icon": ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, _toDisplayString(error.value), 1),
          _createElementVNode("div", _hoisted_1, _toDisplayString(_unref(t)('BotErrorAlert.change')), 1)
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}
}

})