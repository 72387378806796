<template>
  <card-item>
    <template #title>
      {{ t('Home.folders') }}
    </template>
    <template #header>
      <el-tooltip class="box-item" effect="light" placement="right">
        <template #content>
          <span v-html="t('Home.tooltips.folders')"></span>
        </template>
        <el-icon style="margin-left: 5px;" class="tooltip">
          <question-filled />
        </el-icon>
      </el-tooltip>
    </template>
    <Suspense>
      <FolderList />
    </Suspense>
  </card-item>
  <card-item>
    <template #title>
      {{ t('Home.settings') }}
    </template>
    <SettingList />
  </card-item>
  <card-item>
    <template #title>
      {{ t('Home.contacts') }}
    </template>
    <ContactList />
  </card-item>
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue';
import CardItem from '../components/folder/CardItem.vue';
import FolderList from '../components/home/FolderList.vue';
import SettingList from '@/components/home/SettingList.vue';
import ContactList from '@/components/home/ContactList.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  setup() {
    const modal = ref(false);
    const filter = ref({});
    const { t } = useI18n();
    return {
      modal,
      filter,
      t,
    };
  },
  components: {
    CardItem,
    FolderList,
    SettingList,
    ContactList,
  },
});
</script>