import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Suspense as _Suspense, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_question_filled = _resolveComponent("question-filled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_FolderList = _resolveComponent("FolderList")!
  const _component_card_item = _resolveComponent("card-item")!
  const _component_SettingList = _resolveComponent("SettingList")!
  const _component_ContactList = _resolveComponent("ContactList")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_card_item, null, {
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t('Home.folders')), 1)
      ]),
      header: _withCtx(() => [
        _createVNode(_component_el_tooltip, {
          class: "box-item",
          effect: "light",
          placement: "right"
        }, {
          content: _withCtx(() => [
            _createElementVNode("span", {
              innerHTML: _ctx.t('Home.tooltips.folders')
            }, null, 8, _hoisted_1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_icon, {
              style: {"margin-left":"5px"},
              class: "tooltip"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_question_filled)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_Suspense, null, {
          default: _withCtx(() => [
            _createVNode(_component_FolderList)
          ]),
          _: 1
        }))
      ]),
      _: 1
    }),
    _createVNode(_component_card_item, null, {
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t('Home.settings')), 1)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_SettingList)
      ]),
      _: 1
    }),
    _createVNode(_component_card_item, null, {
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t('Home.contacts')), 1)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_ContactList)
      ]),
      _: 1
    })
  ], 64))
}