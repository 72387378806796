<template>
  <div class="common-layout">
    <el-container>
      <el-header class="fixed-top">
        <el-row :gutter="20" justify="space-between" style="width: 650px;">
          <el-col :span="18">
            <h3 class="m-08 h3">
              {{ $t(titleKey) }}
            </h3>
          </el-col>
          <el-col :span="6">
            <el-select-v2 v-model="locale" :options="$i18n.availableLocales.map(s => { return { value: s, label: s } })"
              style="margin-top: 1.5rem;" size="large" />
          </el-col>
        </el-row>
      </el-header>
      <el-main class="main">
        <div class="container">
          <BotTokenError />
          <router-view />
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script setup lang="ts">
import BotTokenError from '@/components/home/BotTokenError.vue'
import { i18n } from '@/main';
import { computed } from 'vue'
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const route = useRoute()
const { t } = useI18n();
const store = useStore()
const locale = computed({
  get() {
    return store.getters['auth/locale']
  },
  set(newValue: string) {
    i18n.global.locale.value = newValue
    store.dispatch('auth/changeLocale', newValue)
  }
})
const titleKey = computed(() => route.meta.titleKey as string);
const title = computed(() => t(titleKey.value));
document.title = title.value;
</script>

<style scoped>
.el-header {
  height: 70px;
  border-bottom: 1px var(--el-border-color) var(--el-border-style);
}
</style>