import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "common-layout" }
const _hoisted_2 = { class: "h3" }
const _hoisted_3 = { class: "h3" }

import EditCancelDialog from '@/components/folder/EditCancelDialog.vue';
import { computed, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'SubPageLayout',
  setup(__props) {

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const store = useStore();

const titleKey = computed(() => route.meta.titleKey as string);
const title = computed(() => t(titleKey.value));
document.title = `${title.value} | ${t('MainLayout.appTitle')}`;

const editFlag = computed(() => store.getters['getEditFlags']);
const editCancelDialog = ref(false);

const onBack = () => {
  if (editFlag.value.length) {
    editCancelDialog.value = true;
  } else {
    router.back();
  }
};

return (_ctx: any,_cache: any) => {
  const _component_Back = _resolveComponent("Back")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_page_header = _resolveComponent("el-page-header")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_header, { class: "fixed-top" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_page_header, {
                class: "m-08 h3",
                onBack: onBack
              }, {
                icon: _withCtx(() => [
                  _createElementVNode("span", _hoisted_2, [
                    _createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_Back)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                title: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createElementVNode("span", null, null, -1)
                ])),
                content: _withCtx(() => [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t(titleKey.value)), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_router_view)
        ]),
        _: 1
      })
    ]),
    _createVNode(EditCancelDialog, {
      modelValue: editCancelDialog.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((editCancelDialog).value = $event))
    }, null, 8, ["modelValue"])
  ], 64))
}
}

})