<template>
  <div class="common-layout">
    <el-container>
      <el-header class="fixed-top">
        <el-page-header class="m-08 h3" @back="onBack">
          <template #icon>
            <span class="h3">
              <el-icon>
                <Back />
              </el-icon>
            </span>
          </template>
          <template #title>
            <span />
          </template>
          <template #content>
            <span class="h3">{{ $t(titleKey) }}</span>
          </template>
        </el-page-header>
      </el-header>
      <router-view />
    </el-container>
  </div>
  <EditCancelDialog v-model="editCancelDialog" />
</template>

<script setup lang="ts">
import EditCancelDialog from '@/components/folder/EditCancelDialog.vue';
import { computed, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const store = useStore();

const titleKey = computed(() => route.meta.titleKey as string);
const title = computed(() => t(titleKey.value));
document.title = `${title.value} | ${t('MainLayout.appTitle')}`;

const editFlag = computed(() => store.getters['getEditFlags']);
const editCancelDialog = ref(false);

const onBack = () => {
  if (editFlag.value.length) {
    editCancelDialog.value = true;
  } else {
    router.back();
  }
};
</script>

<style scoped>
.el-header {
  height: 70px;
  border-bottom: 1px var(--el-border-color) var(--el-border-style);
}
</style>