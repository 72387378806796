import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomePage from "../views/HomePage.vue";
import store from "../store";
import { fitBitrixWindow } from "@/utils/bitrix24";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
    meta: {
      layout: "Main",
      auth: true,
      titleKey: "Router.home.title", // Ключ локализации вместо текста
    },
  },
  {
    path: "/folder/:id",
    name: "Folder",
    component: () => import("../views/FolderPage.vue"),
    meta: {
      layout: "SubPage",
      auth: true,
      titleKey: "Router.folder.title", // Ключ локализации
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("../views/SettingsPage.vue"),
    meta: {
      layout: "SubPage",
      auth: true,
      titleKey: "Router.settings.title", // Ключ локализации
    },
  },
  {
    path: "/templates",
    name: "Templates",
    component: () => import("../views/TemplatePage.vue"),
    meta: {
      layout: "SubPage",
      auth: true,
      titleKey: "Router.templates.title", // Ключ локализации
    },
  },
  {
    path: "/auth",
    name: "Auth",
    component: () => import("../views/AuthPage.vue"),
    meta: {
      layout: "Main",
      auth: false,
      titleKey: "Router.auth.title", // Ключ локализации
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requireAuth = to.meta.auth;
  store.commit("clear");

  if (requireAuth && store.getters["auth/isAuthenticated"]) {
    next();
  } else if (requireAuth && !store.getters["auth/isAuthenticated"]) {
    next("/auth");
  } else {
    next();
  }
});

// Добавляем хук afterEach для изменения размера окна после каждой навигации
router.afterEach(() => {
  fitBitrixWindow();
});

export default router;
