<template>
  <el-alert
    v-if="error"
    @click="open"
    style="cursor: pointer; font-weight: 500;"
    class="card"
    :title="t('BotErrorAlert.title')"
    :closable="false"
    type="error"
    show-icon
  >
    <template #default>
      <div>{{ error }}</div>
      <div style="text-decoration: underline;">{{ t('BotErrorAlert.change') }}</div>
    </template>
  </el-alert>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const store = useStore();
const error = computed(() => store.getters['auth/error']);

const open = () => {
  store.commit('setBotTokenModal', true);
};
</script>

<style scoped></style>