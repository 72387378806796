import { nextTick } from 'vue';

// Интерфейсы для ботов Битрикс24
export interface BitrixBotData {
  ID: number;
  BOT_ID: number;
  NAME?: string;
  LAST_NAME?: string;
  COLOR?: string;
  WORK_POSITION?: string;
  PERSONAL_GENDER?: string;
  PERSONAL_PHOTO?: string;
  AVATAR?: string; // Опциональное поле, оставлено для совместимости
  CODE: string;
}

/**
 * Изменяет размер окна для встраивания в Bitrix24
 * Функция безопасно проверяет наличие объекта BX24 перед вызовом
 */
export const fitBitrixWindow = (): void => {
  nextTick(() => {
    if (typeof window.BX24 !== 'undefined') {
      window.BX24.fitWindow();
    }
  });
};

/**
 * Проверяет наличие объекта BX24
 */
const isBitrix24Available = (): boolean => {
  return typeof window.BX24 !== 'undefined';
};

/**
 * Получает список ботов Битрикс24
 * @returns Promise с массивом ботов
 */
export const getBitrixBotList = (): Promise<BitrixBotData[]> => {
  return new Promise((resolve, reject) => {
    if (!isBitrix24Available()) {
      reject(new Error('Битрикс24 API недоступно'));
      return;
    }

    window.BX24.callMethod(
      'imbot.bot.list',
      {},
      (result: BX24.MethodResult) => {
        if (result.error()) {
          reject(new Error(result.error() || 'Неизвестная ошибка'));
          return;
        }
        
        const bots = result.data() || [];
        resolve(bots);
      }
    );
  });
};

/**
 * Регистрирует нового бота в Битрикс24
 * @param botData данные бота для регистрации
 * @returns Promise с данными созданного бота
 */
export const registerBitrixBot = (botData: Partial<BitrixBotData>): Promise<BitrixBotData> => {
  return new Promise((resolve, reject) => {
    if (!isBitrix24Available()) {
      reject(new Error('Битрикс24 API недоступно'));
      return;
    }

    window.BX24.callMethod(
      'imbot.register',
      botData,
      (result: BX24.MethodResult) => {
        if (result.error()) {
          reject(new Error(result.error() || 'Неизвестная ошибка'));
          return;
        }
        
        const createdBot = result.data()[0] || null;
        if (!createdBot) {
          reject(new Error('Не удалось получить данные созданного бота'));
          return;
        }
        
        resolve(createdBot);
      }
    );
  });
};

/**
 * Удаляет бота из Битрикс24
 * @param botId идентификатор бота для удаления
 * @returns Promise с результатом операции
 */
export const unregisterBitrixBot = (botId: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    if (!isBitrix24Available()) {
      reject(new Error('Битрикс24 API недоступно'));
      return;
    }

    window.BX24.callMethod(
      'imbot.unregister',
      { BOT_ID: botId },
      (result: BX24.MethodResult) => {
        if (result.error()) {
          reject(new Error(result.error() || 'Неизвестная ошибка'));
          return;
        }
        
        resolve(true);
      }
    );
  });
};

/**
 * Обновляет данные существующего бота в Битрикс24
 * @param botData данные бота для обновления (должно содержать BOT_ID)
 * @returns Promise с результатом операции
 */
export const updateBitrixBot = (botData: Partial<BitrixBotData>): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    if (!isBitrix24Available()) {
      reject(new Error('Битрикс24 API недоступно'));
      return;
    }
    
    if (!botData.BOT_ID) {
      reject(new Error('Не указан идентификатор бота для обновления'));
      return;
    }

    // Создаем структуру данных согласно требованиям API
    const apiData = {
      BOT_ID: botData.BOT_ID,
      CLIENT_ID: '',
      FIELDS: {
        CODE: botData.CODE,
        PROPERTIES: {
          NAME: botData.NAME,
          LAST_NAME: botData.LAST_NAME || '',
          COLOR: botData.COLOR,
          WORK_POSITION: botData.WORK_POSITION || '',
          PERSONAL_GENDER: botData.PERSONAL_GENDER,
          PERSONAL_PHOTO: botData.PERSONAL_PHOTO || '',
        }
      }
    };

    window.BX24.callMethod(
      'imbot.update',
      apiData,
      (result: BX24.MethodResult) => {
        if (result.error()) {
          reject(new Error(result.error() || 'Неизвестная ошибка'));
          return;
        }
        
        // API возвращает result: true при успешном обновлении
        const resultData = result.data();
        if (resultData) {
          if (typeof resultData === 'boolean') {
            resolve(resultData);
          } else if (typeof resultData === 'object' && 'result' in resultData && resultData.result === true) {
            resolve(true);
          } else {
            resolve(true); // Предполагаем успех, если формат ответа неожиданный, но ошибки нет
          }
        } else {
          reject(new Error('Не удалось обновить бота'));
        }
      }
    );
  });
}; 