import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "p-0-05" }
const _hoisted_2 = { class: "secondary" }
const _hoisted_3 = {
  key: 0,
  class: "secondary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_ArrowRightBold = _resolveComponent("ArrowRightBold")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_row = _resolveComponent("el-row")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_divider)
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.folders, (folder) => {
        return (_openBlock(), _createBlock(_component_el_row, {
          key: folder.id,
          class: "folder-row",
          onClick: ($event: any) => (_ctx.navigate(folder.id))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 2 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_avatar, {
                  icon: "folder",
                  size: 50
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 21,
              class: "pl-1"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, _toDisplayString(folder.uf_name), 1),
                _createElementVNode("div", _hoisted_2, [
                  _createTextVNode(_toDisplayString(_ctx.t('FolderList.chats')) + ": " + _toDisplayString(folder.uf_count_chats ?? 0) + " ", 1),
                  (folder.uf_count_errors)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                        _cache[2] || (_cache[2] = _createTextVNode("  • ")),
                        _createElementVNode("span", null, _toDisplayString(_ctx.t('FolderList.errors')) + ": " + _toDisplayString(folder.uf_count_errors ?? 0), 1)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_el_col, {
              span: 1,
              class: "arrow-right"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ArrowRightBold)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 2
        }, 1032, ["onClick"]))
      }), 128)),
      (_ctx.totalItemsCount > _ctx.folders.length)
        ? (_openBlock(), _createBlock(_component_el_row, {
            key: 0,
            class: "folder-row",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMore()))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 2 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_avatar, {
                    icon: "arrow-down",
                    size: 50,
                    class: "link"
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, {
                span: 22,
                class: "m0 link pl-1"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, _toDisplayString(_ctx.t('FolderList.showMore')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_el_row, {
        class: "folder-row",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.newFolder()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 2 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_avatar, {
                icon: "plus",
                size: 50,
                class: "link"
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, {
            span: 22,
            class: "m0 link pl-1"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, _toDisplayString(_ctx.t('FolderList.createFolder')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])), [
      [_directive_loading, _ctx.loading]
    ])
  ], 64))
}