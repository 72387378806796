import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-0-05" }
const _hoisted_2 = { class: "m0" }
const _hoisted_3 = {
  key: 0,
  class: "secondary"
}

import { Link } from '@/models/link';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import BotTokenDialog from './BotTokenDialog.vue';
import BitrixBotDialog from './BitrixBotDialog.vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'SettingList',
  setup(__props) {

const { t } = useI18n();
const router = useRouter();
const store = useStore();
const linkList: Link[] = [
  {
    title: 'SettingsLinks.generalSettings',
    arrow: true,
    link: () => {
      router.push('/settings');
    },
  },
  {
    title: 'SettingsLinks.notificationTemplates',
    arrow: true,
    link: () => {
      router.push('/templates');
    },
  },
  {
    title: 'SettingsLinks.telegramBotSettings',
    arrow: false,
    link: () => {
      store.commit('setBotTokenModal', true);
    },
  },
  {
    title: 'SettingsLinks.bitrixBotSettings',
    arrow: false,
    link: () => {
      store.commit('setBitrixBotModal', true);
    },
  },
];

return (_ctx: any,_cache: any) => {
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_ArrowRightBold = _resolveComponent("ArrowRightBold")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_divider)
    ]),
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(linkList, (link) => {
      return _createVNode(_component_el_row, {
        key: link.link,
        class: "folder-row",
        onClick: link.link
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, {
            span: 23,
            class: "pl-1 mh40 m0"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(t)(link.title)), 1),
              (link.subtitle)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(link.subtitle), 1))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1024),
          (link.arrow)
            ? (_openBlock(), _createBlock(_component_el_col, {
                key: 0,
                span: 1,
                class: "arrow-right"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ArrowRightBold)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1032, ["onClick"])
    }), 64)),
    _createVNode(BotTokenDialog),
    _createVNode(BitrixBotDialog)
  ], 64))
}
}

})