<template>
  <el-dialog v-model="modelValue" :show-close="false" width="500">
    <template #header>
      <div>
        <h4>{{ t('BotTokenDialog.title') }}</h4>
        <div class="secondary pt-1" style="text-align: center;">
          {{ t('BotTokenDialog.description') }}
        </div>
      </div>
    </template>
    <el-divider />
    <div v-loading="loading">
      <div class="custom-style pt-1">
        <div>
          <span class="secondary">{{ t('BotTokenDialog.selectBot') }}</span>
          <el-tooltip effect="light" placement="top">
            <template #content>
              <span class="tooltip-text">{{ t('BotTokenDialog.tooltips.selectBot') }}</span>
            </template>
            <el-icon class="tooltip">
              <question-filled />
            </el-icon>
          </el-tooltip>
        </div>
        <el-radio-group v-model="radio1" style="padding-left: 5px; padding-top: 5px;">
          <div>
            <el-radio value="bx24tgbot">{{ t('BotTokenDialog.useStandard') }}</el-radio>
          </div>
          <div>
            <el-radio value="self">{{ t('BotTokenDialog.useCustom') }}</el-radio>
          </div>
        </el-radio-group>
      </div>
      <div class="custom-style pt-1" v-if="radio1 == 'self'">
        <div>
          <span class="secondary">{{ t('BotTokenDialog.telegramBotToken') }}</span>
          <el-tooltip effect="light" placement="top">
            <template #content>
              <span class="tooltip-text">{{ t('BotTokenDialog.tooltips.telegramBotToken') }}</span>
            </template>
            <el-icon class="tooltip">
              <question-filled />
            </el-icon>
          </el-tooltip>
        </div>
        <el-input :class="error ? 'red-border' : ''" size="large" v-model="input"
          :placeholder="t('BotTokenDialog.enterTokenPlaceholder')" style="width: 100%; padding: 1rem 0;"></el-input>
        <small>{{ error }}</small>
      </div>
      <el-divider />
      <el-row class="p-7" style="display: flex; flex-direction: row-reverse;">
        <el-button :disabled="bSave" style="height: 40px;" class="primary" @click="save" type="primary">
          {{ t('BotTokenDialog.save') }}
        </el-button>
        <el-button style="height: 40px; margin-right: 1rem;" @click="close" link>
          {{ t('BotTokenDialog.cancel') }}
        </el-button>
      </el-row>
    </div>
  </el-dialog>
</template>

<script setup lang="ts">
import { getSettingList, updateSetting } from '@/api/settings';
import { SettingList } from '@/models/settings';
import { AxiosResponse } from 'axios';
import { ElMessage } from 'element-plus';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { errText } from '@/store';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const store = useStore();
const radio1 = ref('bx24tgbot');
const error = ref();
const input = ref();
const bSave = ref();
const loading = ref(false);

const loadData = async () => {
  loading.value = true;
  await getSettingList()
    .then((response: AxiosResponse<SettingList>) => {
      radio1.value = response.data.selectType ?? '';
      input.value = response.data.tgToken;
      loading.value = false;
    })
    .catch((error) => {
      console.error(error);
      ElMessage({
        message: t('BotTokenDialog.errorFetchingSettings'),
        type: 'error',
        duration: 3000,
      });
    });
};

const save = async () => {
  loading.value = true;
  if (radio1.value === 'bx24tgbot') {
    input.value = '';
  }
  const req: SettingList = {
    tgToken: input.value,
    selectType: radio1.value,
  };
  await updateSetting(req)
    .then((response) => {
      if (response.data.code) {
        error.value = errText(response.data.code);
      } else {
        error.value = null;
        store.commit('setBotTokenModal', false);
        store.commit('auth/setDomainError', 0);
        setTimeout(() => {
          ElMessage({
            message: t('BotTokenDialog.successMessage'),
            type: 'success',
            duration: 3000,
          });
        }, 500);
      }
      loading.value = false;
    })
    .catch((error) => {
      console.error(error);
      ElMessage({
        message: t('BotTokenDialog.errorUpdatingSettings'),
        type: 'error',
        duration: 3000,
      });
    });
};

const st = computed(() => store.getters['getBotTokenModal']);
const modelValue = ref(false);

const close = () => {
  store.commit('setBotTokenModal', false);
};

watch(st, (newX) => {
  modelValue.value = newX;
});

watch(input, (newX) => {
  if (newX.length == 0 && radio1.value === 'self' && !bSave.value) {
    bSave.value = true;
  } else if (bSave.value && newX.length > 0) {
    bSave.value = false;
  }
});

watch(radio1, (newX) => {
  if (input.value.length == 0 && newX === 'self' && !bSave.value) {
    bSave.value = true;
  } else if (bSave.value && newX === 'bx24tgbot') {
    bSave.value = false;
  }
});

watch(modelValue, (newX) => {
  if (newX) {
    loadData();
  } else {
    bSave.value = null;
    error.value = null;
  }
  store.commit('setBotTokenModal', newX);
});
</script>

<style scoped>
.secondary {
  font-size: 16px;
}

.el-radio-group {
  display: block;
  line-height: normal;
}

h4 {
  font-size: 1.2rem;
  padding: 1rem 0rem;
  margin: 0;
  border-bottom: 1px solid #eaecef;
}
</style>