import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-0-05" }
const _hoisted_2 = { class: "m0" }
const _hoisted_3 = {
  key: 0,
  class: "secondary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_divider)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contacts, (link) => {
      return (_openBlock(), _createBlock(_component_el_row, {
        key: link.link
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, {
            span: 23,
            class: "pl-1 mh40 m0"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, _toDisplayString(link.title), 1),
              (link.subtitle)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(link.subtitle), 1))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1024))
    }), 128))
  ], 64))
}