<template>
  <component :is="layout + 'Layout'" :title="title" v-if="layout" />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import MainLayout from './layout/MainLayout.vue'
import SubPageLayout from './layout/SubPageLayout.vue'

export default defineComponent({
  setup() {
    const route = useRoute()

    return {
      layout: computed(() => route.meta.layout),
      title: computed(() => route.meta.title)
    }
  },
  components: { MainLayout, SubPageLayout }
})
</script>