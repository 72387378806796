import { computed, createApp } from 'vue'
import { createI18n } from "vue-i18n"
import en from "./locales/en.json"
import ru from "./locales/ru.json"
import ua from "./locales/ua.json"
import App from './App.vue'
import router from './router'
import ElementPlus from "element-plus"
import "element-plus/dist/index.css"
import * as ElementPlusIconsVue from "@element-plus/icons-vue"
import "./theme.css"
import store from './store'

const savedLocale = computed(() => store.getters["auth/locale"])
const defaultLocale = savedLocale.value || "ru";
export const i18n = createI18n({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: "en",
  messages: {
    "en": en,
    "ru": ru,
    "ua": ua,
  },
});
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(i18n).use(ElementPlus).use(store).use(router).mount('#app')
