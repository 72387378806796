import store from "@/store";
import axios, {
  AxiosError,
  InternalAxiosRequestConfig,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";

// For Make Log on Develop Mode
const logOnDev = (message: string) => {
  if (process.env.NODE_ENV === "development") {
    console.log(message);
  }
};

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

const onRequest = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  const token = store.getters["auth/token"];
  if (store.getters["auth/token"]) {
    config.headers["Authorization"] = "Bearer " + token;
    config.headers["Content-Type"] = "application/json";
  }
  return config;
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  const { method, url } = response.config;
  const { status } = response;
  // Set Loading End Here
  // Handle Response Data Here
  // Error Handling When Return Success with Error Code Here
  logOnDev(`🚀 [API] ${method?.toUpperCase()} ${url} | Response ${status}`);

  return response;
};

const onErrorResponse = (error: AxiosError | Error): Promise<AxiosError> => {
  if (axios.isAxiosError(error)) {
    const { message } = error;
    const { method, url } = error.config as AxiosRequestConfig;
    const { status } = (error.response as AxiosResponse) ?? {};

    logOnDev(
      `🚨 [API] ${method?.toUpperCase()} ${url} | Status ${status} Error ${message}`
    );

    switch (status) {
      case 401: {
        store.commit("auth/logout")
        location.reload();
        break;
      }
      case 403: {
        // "Permission denied"
        break;
      }
      case 404: {
        // "Invalid request"
        break;
      }
      case 500: {
        // "Server error"
        break;
      }
      default: {
        // store.commit("auth/logout")
        // location.reload();
        break;
      }
    }
  } else {
    logOnDev(`🚨 [API] | Error ${error.message}`);
  }

  return Promise.reject(error);
};

api.interceptors.request.use(onRequest, onErrorResponse);
api.interceptors.response.use(onResponse, onErrorResponse);

export default api;
