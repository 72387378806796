import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "secondary pt-1",
  style: {"text-align":"center"}
}
const _hoisted_2 = { class: "custom-style pt-1" }
const _hoisted_3 = { class: "secondary" }
const _hoisted_4 = { class: "tooltip-text" }
const _hoisted_5 = { class: "p-1 pt-2" }
const _hoisted_6 = {
  key: 0,
  class: "bot-settings"
}
const _hoisted_7 = { class: "custom-style pt-1" }
const _hoisted_8 = { class: "secondary" }
const _hoisted_9 = { class: "custom-style pt-1" }
const _hoisted_10 = { class: "secondary" }
const _hoisted_11 = { class: "custom-style pt-1" }
const _hoisted_12 = { class: "secondary" }
const _hoisted_13 = { class: "custom-style pt-1" }
const _hoisted_14 = { class: "secondary" }
const _hoisted_15 = { class: "custom-style pt-1" }
const _hoisted_16 = { class: "secondary" }
const _hoisted_17 = { class: "custom-style pt-1" }
const _hoisted_18 = { class: "secondary" }
const _hoisted_19 = {
  key: 0,
  class: "bot-photo-preview"
}
const _hoisted_20 = ["src"]

import { getBitrixBotList, registerBitrixBot, unregisterBitrixBot, updateBitrixBot, BitrixBotData } from '@/utils/bitrix24';
import { ElMessage } from 'element-plus';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'BitrixBotDialog',
  setup(__props) {

const { t } = useI18n();
const store = useStore();
const loading = ref(false);
const modelValue = ref(false);
const botEnabled = ref(false);
const canSave = ref(true);
const botPhotoPreview = ref('');
const botPhotoFile = ref<File | null>(null);

// Доступные цвета бота
const availableColors = computed(() => [
  { value: 'RED', label: t('BitrixBotDialog.colors.red') },
  { value: 'GREEN', label: t('BitrixBotDialog.colors.green') },
  { value: 'MINT', label: t('BitrixBotDialog.colors.mint') },
  { value: 'LIGHT_BLUE', label: t('BitrixBotDialog.colors.lightBlue') },
  { value: 'DARK_BLUE', label: t('BitrixBotDialog.colors.darkBlue') },
  { value: 'PURPLE', label: t('BitrixBotDialog.colors.purple') },
  { value: 'AQUA', label: t('BitrixBotDialog.colors.aqua') },
  { value: 'PINK', label: t('BitrixBotDialog.colors.pink') },
  { value: 'LIME', label: t('BitrixBotDialog.colors.lime') },
  { value: 'BROWN', label: t('BitrixBotDialog.colors.brown') },
  { value: 'AZURE', label: t('BitrixBotDialog.colors.azure') },
  { value: 'KHAKI', label: t('BitrixBotDialog.colors.khaki') },
  { value: 'SAND', label: t('BitrixBotDialog.colors.sand') },
  { value: 'MARENGO', label: t('BitrixBotDialog.colors.marengo') },
  { value: 'GRAY', label: t('BitrixBotDialog.colors.gray') },
  { value: 'GRAPHITE', label: t('BitrixBotDialog.colors.graphite') }
]);

// Данные бота
const botData = ref<BitrixBotData>({
  ID: 0,
  BOT_ID: 0,
  NAME: '',
  LAST_NAME: '',
  COLOR: 'GREEN',
  WORK_POSITION: '',
  PERSONAL_GENDER: 'M',
  PERSONAL_PHOTO: '',
  CODE: 'Telegram'
});

// Проверка наличия бота по API imbot.bot.list
const checkBotStatus = async () => {
  loading.value = true;
  try {
    const botsObj = await getBitrixBotList();
    // Преобразуем объект в массив
    const bots = (Array.isArray(botsObj) ? botsObj : Object.values(botsObj)) as BitrixBotData[];
    // Теперь ищем бота с кодом "Telegram"
    const telegramBot = bots.find(bot => bot.CODE === "Telegram");
    
    if (telegramBot) {
      botEnabled.value = true;
      // Используем данные найденного бота
      botData.value = {
        ...botData.value,
        ...telegramBot,
        BOT_ID: telegramBot.ID
      };
      
      // Если у бота есть аватар, отображаем его
      if (telegramBot.PERSONAL_PHOTO) {
        botPhotoPreview.value = telegramBot.PERSONAL_PHOTO;
      }
    } else {
      botEnabled.value = false;
    }
  } catch (error) {
    console.error(error);
    ElMessage({
      message: t('BitrixBotDialog.errorCheckingBot'),
      type: 'error',
      duration: 3000,
    });
  } finally {
    loading.value = false;
  }
};

// Переключение состояния бота (установка/удаление)
const toggleBot = async (value: boolean) => {
  loading.value = true;
  try {
    if (value) {
      // Создаем бота с кодом "Telegram"
      const botSettings: Partial<BitrixBotData> = {
        NAME: 'Bitrix24<=>Telegram',
        COLOR: 'GREEN',
        CODE: 'Telegram' // Устанавливаем код "Telegram"
      };
      
      const createdBot = await registerBitrixBot(botSettings);
      
      if (createdBot) {
        botData.value = {
          ...botData.value,
          ...createdBot
        };
        ElMessage({
          message: t('BitrixBotDialog.botCreated'),
          type: 'success',
          duration: 3000,
        });
      } else {
        botEnabled.value = false;
        ElMessage({
          message: t('BitrixBotDialog.errorCreatingBot'),
          type: 'error',
          duration: 3000,
        });
      }
    } else {
      // Удаляем бота
      if (!botData.value.BOT_ID) {
        ElMessage({
          message: t('BitrixBotDialog.noBotToRemove'),
          type: 'warning',
          duration: 3000,
        });
        botEnabled.value = false;
        loading.value = false;
        return;
      }
      
      const success = await unregisterBitrixBot(botData.value.BOT_ID.toString());
      
      if (success) {
        botData.value = {
          ID: 0,
          BOT_ID: 0,
          NAME: '',
          LAST_NAME: '',
          COLOR: 'GREEN',
          WORK_POSITION: '',
          PERSONAL_GENDER: 'M',
          PERSONAL_PHOTO: '',
          CODE: 'Telegram'
        };
        botPhotoPreview.value = '';
        ElMessage({
          message: t('BitrixBotDialog.botRemoved'),
          type: 'success',
          duration: 3000,
        });
      } else {
        botEnabled.value = true;
        ElMessage({
          message: t('BitrixBotDialog.errorRemovingBot'),
          type: 'error',
          duration: 3000,
        });
      }
    }
  } catch (error) {
    console.error(error);
    botEnabled.value = !value; // Возвращаем предыдущее состояние при ошибке
    ElMessage({
      message: t('BitrixBotDialog.errorTogglingBot'),
      type: 'error',
      duration: 3000,
    });
  } finally {
    loading.value = false;
  }
};

// Обработка выбора фотографии
const handlePhotoChange = (file: { raw: File }) => {
  if (file && file.raw) {
    botPhotoFile.value = file.raw;
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target && e.target.result) {
        botPhotoPreview.value = e.target.result.toString();
        // Конвертируем фото в base64 для отправки на сервер
        botData.value.PERSONAL_PHOTO = e.target.result.toString();
      }
    };
    reader.readAsDataURL(file.raw);
  }
};

// Сохранение настроек бота
const save = async () => {
  loading.value = true;
  try {
    if (botEnabled.value) {
      if (botData.value.BOT_ID) {
        // Преобразуем ID в числовой формат, если это требуется
        if (typeof botData.value.BOT_ID === 'string') {
          botData.value.BOT_ID = parseInt(botData.value.BOT_ID, 10);
        }
        
        // Убедимся, что CODE установлен
        if (!botData.value.CODE) {
          botData.value.CODE = 'Telegram';
        }
        
        // Обновляем существующего бота
        const result = await updateBitrixBot(botData.value);
        
        if (result) {
          ElMessage({
            message: t('BitrixBotDialog.botUpdated'),
            type: 'success',
            duration: 3000,
          });
          close();
        } else {
          ElMessage({
            message: t('BitrixBotDialog.errorUpdatingBot'),
            type: 'error',
            duration: 3000,
          });
        }
      } else {
        // Создаем нового бота, если его нет
        const botSettings: Partial<BitrixBotData> = {
          ...botData.value,
          CODE: 'Telegram'
        };
        
        const createdBot = await registerBitrixBot(botSettings);
        
        if (createdBot) {
          ElMessage({
            message: t('BitrixBotDialog.botCreated'),
            type: 'success',
            duration: 3000,
          });
          close();
        } else {
          ElMessage({
            message: t('BitrixBotDialog.errorCreatingBot'),
            type: 'error',
            duration: 3000,
          });
        }
      }
    } else {
      // Просто закрываем диалог, если бот отключен
      close();
    }
  } catch (error) {
    console.error('Ошибка при сохранении настроек бота:', error);
    ElMessage({
      message: t('BitrixBotDialog.errorSavingSettings'),
      type: 'error',
      duration: 3000,
    });
  } finally {
    loading.value = false;
  }
};

// Наблюдение за состоянием модального окна в хранилище
const dialogOpen = computed(() => store.getters['getBitrixBotModal']);

// Закрытие диалога
const close = () => {
  store.commit('setBitrixBotModal', false);
};

// Загрузка данных при открытии диалога
const loadData = () => {
  checkBotStatus();
};

watch(dialogOpen, (newValue) => {
  modelValue.value = newValue;
  if (newValue) {
    loadData();
  }
});

watch(modelValue, (newValue) => {
  store.commit('setBitrixBotModal', newValue);
});

return (_ctx: any,_cache: any) => {
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_question_filled = _resolveComponent("question-filled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: modelValue.value,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((modelValue).value = $event)),
    "show-close": false,
    width: "500"
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("h4", null, _toDisplayString(_unref(t)('BitrixBotDialog.title')), 1),
        _createElementVNode("div", _hoisted_1, _toDisplayString(_unref(t)('BitrixBotDialog.description')), 1)
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_divider),
      _withDirectives((_openBlock(), _createElementBlock("div", null, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(t)('BitrixBotDialog.botStatus')), 1),
            _createVNode(_component_el_tooltip, {
              effect: "light",
              placement: "top"
            }, {
              content: _withCtx(() => [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(t)('BitrixBotDialog.tooltips.botStatus')), 1)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { class: "tooltip" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_question_filled)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_el_switch, {
              modelValue: botEnabled.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((botEnabled).value = $event)),
              size: "large",
              "active-text": _unref(t)('BitrixBotDialog.botEnabled'),
              "inactive-text": _unref(t)('BitrixBotDialog.botDisabled'),
              onChange: toggleBot
            }, null, 8, ["modelValue", "active-text", "inactive-text"])
          ])
        ]),
        (botEnabled.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", null, [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(t)('BitrixBotDialog.botName')), 1)
                ]),
                _createVNode(_component_el_input, {
                  modelValue: botData.value.NAME,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((botData.value.NAME) = $event)),
                  size: "large",
                  style: {"width":"100%","padding":"0.5rem 0"}
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", null, [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_unref(t)('BitrixBotDialog.botLastName')), 1)
                ]),
                _createVNode(_component_el_input, {
                  modelValue: botData.value.LAST_NAME,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((botData.value.LAST_NAME) = $event)),
                  size: "large",
                  style: {"width":"100%","padding":"0.5rem 0"}
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", null, [
                  _createElementVNode("span", _hoisted_12, _toDisplayString(_unref(t)('BitrixBotDialog.botPosition')), 1)
                ]),
                _createVNode(_component_el_input, {
                  modelValue: botData.value.WORK_POSITION,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((botData.value.WORK_POSITION) = $event)),
                  size: "large",
                  style: {"width":"100%","padding":"0.5rem 0"}
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", null, [
                  _createElementVNode("span", _hoisted_14, _toDisplayString(_unref(t)('BitrixBotDialog.botGender')), 1)
                ]),
                _createVNode(_component_el_radio_group, {
                  modelValue: botData.value.PERSONAL_GENDER,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((botData.value.PERSONAL_GENDER) = $event)),
                  style: {"padding-left":"5px","padding-top":"5px"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_radio, { label: "M" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(t)('BitrixBotDialog.male')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_radio, { label: "F" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(t)('BitrixBotDialog.female')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", null, [
                  _createElementVNode("span", _hoisted_16, _toDisplayString(_unref(t)('BitrixBotDialog.botColor')), 1)
                ]),
                _createVNode(_component_el_select, {
                  modelValue: botData.value.COLOR,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((botData.value.COLOR) = $event)),
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(availableColors.value, (color) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: color.value,
                        label: color.label,
                        value: color.value
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", null, [
                  _createElementVNode("span", _hoisted_18, _toDisplayString(_unref(t)('BitrixBotDialog.botPhoto')), 1)
                ]),
                _createVNode(_component_el_upload, {
                  action: "#",
                  "auto-upload": false,
                  "on-change": handlePhotoChange,
                  "show-file-list": false,
                  limit: 1,
                  accept: "image/*"
                }, {
                  trigger: _withCtx(() => [
                    _createVNode(_component_el_button, { type: "primary" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(t)('BitrixBotDialog.selectPhoto')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (botPhotoPreview.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                      _createElementVNode("img", {
                        src: botPhotoPreview.value,
                        alt: "Bot photo preview"
                      }, null, 8, _hoisted_20)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_el_divider),
        _createVNode(_component_el_row, {
          class: "p-7",
          style: {"display":"flex","flex-direction":"row-reverse"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              disabled: !canSave.value,
              style: {"height":"40px"},
              class: "primary",
              onClick: save,
              type: "primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)('BitrixBotDialog.save')), 1)
              ]),
              _: 1
            }, 8, ["disabled"]),
            _createVNode(_component_el_button, {
              style: {"height":"40px","margin-right":"1rem"},
              onClick: close,
              link: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)('BitrixBotDialog.cancel')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])), [
        [_directive_loading, loading.value]
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})