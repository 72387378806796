import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "common-layout" }
const _hoisted_2 = { class: "m-08 h3" }
const _hoisted_3 = { class: "container" }

import BotTokenError from '@/components/home/BotTokenError.vue'
import { i18n } from '@/main';
import { computed } from 'vue'
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'


export default /*@__PURE__*/_defineComponent({
  __name: 'MainLayout',
  setup(__props) {

const route = useRoute()
const { t } = useI18n();
const store = useStore()
const locale = computed({
  get() {
    return store.getters['auth/locale']
  },
  set(newValue: string) {
    i18n.global.locale.value = newValue
    store.dispatch('auth/changeLocale', newValue)
  }
})
const titleKey = computed(() => route.meta.titleKey as string);
const title = computed(() => t(titleKey.value));
document.title = title.value;

return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_select_v2 = _resolveComponent("el-select-v2")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_header, { class: "fixed-top" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, {
              gutter: 20,
              justify: "space-between",
              style: {"width":"650px"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 18 }, {
                  default: _withCtx(() => [
                    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t(titleKey.value)), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 6 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select_v2, {
                      modelValue: locale.value,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((locale).value = $event)),
                      options: _ctx.$i18n.availableLocales.map(s => { return { value: s, label: s } }),
                      style: {"margin-top":"1.5rem"},
                      size: "large"
                    }, null, 8, ["modelValue", "options"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_main, { class: "main" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(BotTokenError),
              _createVNode(_component_router_view)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})