<template>
  <el-dialog v-model="modelValue" :show-close="false" width="500">
    <template #header>
      <div>
        <h4>{{ t('BitrixBotDialog.title') }}</h4>
        <div class="secondary pt-1" style="text-align: center;">
          {{ t('BitrixBotDialog.description') }}
        </div>
      </div>
    </template>
    <el-divider />
    <div v-loading="loading">
      <div class="custom-style pt-1">
        <div>
          <span class="secondary">{{ t('BitrixBotDialog.botStatus') }}</span>
          <el-tooltip effect="light" placement="top">
            <template #content>
              <span class="tooltip-text">{{ t('BitrixBotDialog.tooltips.botStatus') }}</span>
            </template>
            <el-icon class="tooltip">
              <question-filled />
            </el-icon>
          </el-tooltip>
        </div>
        <div class="p-1 pt-2">
          <el-switch
            v-model="botEnabled"
            size="large"
            :active-text="t('BitrixBotDialog.botEnabled')"
            :inactive-text="t('BitrixBotDialog.botDisabled')"
            @change="toggleBot"
          />
        </div>
      </div>
      
      <div v-if="botEnabled" class="bot-settings">
        <div class="custom-style pt-1">
          <div>
            <span class="secondary">{{ t('BitrixBotDialog.botName') }}</span>
          </div>
          <el-input v-model="botData.NAME" size="large" style="width: 100%; padding: 0.5rem 0;"></el-input>
        </div>
        
        <div class="custom-style pt-1">
          <div>
            <span class="secondary">{{ t('BitrixBotDialog.botLastName') }}</span>
          </div>
          <el-input v-model="botData.LAST_NAME" size="large" style="width: 100%; padding: 0.5rem 0;"></el-input>
        </div>
        
        <div class="custom-style pt-1">
          <div>
            <span class="secondary">{{ t('BitrixBotDialog.botPosition') }}</span>
          </div>
          <el-input v-model="botData.WORK_POSITION" size="large" style="width: 100%; padding: 0.5rem 0;"></el-input>
        </div>
        
        <div class="custom-style pt-1">
          <div>
            <span class="secondary">{{ t('BitrixBotDialog.botGender') }}</span>
          </div>
          <el-radio-group v-model="botData.PERSONAL_GENDER" style="padding-left: 5px; padding-top: 5px;">
            <el-radio label="M">{{ t('BitrixBotDialog.male') }}</el-radio>
            <el-radio label="F">{{ t('BitrixBotDialog.female') }}</el-radio>
          </el-radio-group>
        </div>
        
        <div class="custom-style pt-1">
          <div>
            <span class="secondary">{{ t('BitrixBotDialog.botColor') }}</span>
          </div>
          <el-select v-model="botData.COLOR" style="width: 100%;">
            <el-option
              v-for="color in availableColors"
              :key="color.value"
              :label="color.label"
              :value="color.value"
            ></el-option>
          </el-select>
        </div>
        
        <div class="custom-style pt-1">
          <div>
            <span class="secondary">{{ t('BitrixBotDialog.botPhoto') }}</span>
          </div>
          <el-upload
            action="#"
            :auto-upload="false"
            :on-change="handlePhotoChange"
            :show-file-list="false"
            :limit="1"
            accept="image/*"
          >
            <template #trigger>
              <el-button type="primary">{{ t('BitrixBotDialog.selectPhoto') }}</el-button>
            </template>
          </el-upload>
          <div v-if="botPhotoPreview" class="bot-photo-preview">
            <img :src="botPhotoPreview" alt="Bot photo preview" />
          </div>
        </div>
      </div>
      
      <el-divider />
      <el-row class="p-7" style="display: flex; flex-direction: row-reverse;">
        <el-button :disabled="!canSave" style="height: 40px;" class="primary" @click="save" type="primary">
          {{ t('BitrixBotDialog.save') }}
        </el-button>
        <el-button style="height: 40px; margin-right: 1rem;" @click="close" link>
          {{ t('BitrixBotDialog.cancel') }}
        </el-button>
      </el-row>
    </div>
  </el-dialog>
</template>

<script setup lang="ts">
import { getBitrixBotList, registerBitrixBot, unregisterBitrixBot, updateBitrixBot, BitrixBotData } from '@/utils/bitrix24';
import { ElMessage } from 'element-plus';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const store = useStore();
const loading = ref(false);
const modelValue = ref(false);
const botEnabled = ref(false);
const canSave = ref(true);
const botPhotoPreview = ref('');
const botPhotoFile = ref<File | null>(null);

// Доступные цвета бота
const availableColors = computed(() => [
  { value: 'RED', label: t('BitrixBotDialog.colors.red') },
  { value: 'GREEN', label: t('BitrixBotDialog.colors.green') },
  { value: 'MINT', label: t('BitrixBotDialog.colors.mint') },
  { value: 'LIGHT_BLUE', label: t('BitrixBotDialog.colors.lightBlue') },
  { value: 'DARK_BLUE', label: t('BitrixBotDialog.colors.darkBlue') },
  { value: 'PURPLE', label: t('BitrixBotDialog.colors.purple') },
  { value: 'AQUA', label: t('BitrixBotDialog.colors.aqua') },
  { value: 'PINK', label: t('BitrixBotDialog.colors.pink') },
  { value: 'LIME', label: t('BitrixBotDialog.colors.lime') },
  { value: 'BROWN', label: t('BitrixBotDialog.colors.brown') },
  { value: 'AZURE', label: t('BitrixBotDialog.colors.azure') },
  { value: 'KHAKI', label: t('BitrixBotDialog.colors.khaki') },
  { value: 'SAND', label: t('BitrixBotDialog.colors.sand') },
  { value: 'MARENGO', label: t('BitrixBotDialog.colors.marengo') },
  { value: 'GRAY', label: t('BitrixBotDialog.colors.gray') },
  { value: 'GRAPHITE', label: t('BitrixBotDialog.colors.graphite') }
]);

// Данные бота
const botData = ref<BitrixBotData>({
  ID: 0,
  BOT_ID: 0,
  NAME: '',
  LAST_NAME: '',
  COLOR: 'GREEN',
  WORK_POSITION: '',
  PERSONAL_GENDER: 'M',
  PERSONAL_PHOTO: '',
  CODE: 'Telegram'
});

// Проверка наличия бота по API imbot.bot.list
const checkBotStatus = async () => {
  loading.value = true;
  try {
    const botsObj = await getBitrixBotList();
    // Преобразуем объект в массив
    const bots = (Array.isArray(botsObj) ? botsObj : Object.values(botsObj)) as BitrixBotData[];
    // Теперь ищем бота с кодом "Telegram"
    const telegramBot = bots.find(bot => bot.CODE === "Telegram");
    
    if (telegramBot) {
      botEnabled.value = true;
      // Используем данные найденного бота
      botData.value = {
        ...botData.value,
        ...telegramBot,
        BOT_ID: telegramBot.ID
      };
      
      // Если у бота есть аватар, отображаем его
      if (telegramBot.PERSONAL_PHOTO) {
        botPhotoPreview.value = telegramBot.PERSONAL_PHOTO;
      }
    } else {
      botEnabled.value = false;
    }
  } catch (error) {
    console.error(error);
    ElMessage({
      message: t('BitrixBotDialog.errorCheckingBot'),
      type: 'error',
      duration: 3000,
    });
  } finally {
    loading.value = false;
  }
};

// Переключение состояния бота (установка/удаление)
const toggleBot = async (value: boolean) => {
  loading.value = true;
  try {
    if (value) {
      // Создаем бота с кодом "Telegram"
      const botSettings: Partial<BitrixBotData> = {
        NAME: 'Bitrix24<=>Telegram',
        COLOR: 'GREEN',
        CODE: 'Telegram' // Устанавливаем код "Telegram"
      };
      
      const createdBot = await registerBitrixBot(botSettings);
      
      if (createdBot) {
        botData.value = {
          ...botData.value,
          ...createdBot
        };
        ElMessage({
          message: t('BitrixBotDialog.botCreated'),
          type: 'success',
          duration: 3000,
        });
      } else {
        botEnabled.value = false;
        ElMessage({
          message: t('BitrixBotDialog.errorCreatingBot'),
          type: 'error',
          duration: 3000,
        });
      }
    } else {
      // Удаляем бота
      if (!botData.value.BOT_ID) {
        ElMessage({
          message: t('BitrixBotDialog.noBotToRemove'),
          type: 'warning',
          duration: 3000,
        });
        botEnabled.value = false;
        loading.value = false;
        return;
      }
      
      const success = await unregisterBitrixBot(botData.value.BOT_ID.toString());
      
      if (success) {
        botData.value = {
          ID: 0,
          BOT_ID: 0,
          NAME: '',
          LAST_NAME: '',
          COLOR: 'GREEN',
          WORK_POSITION: '',
          PERSONAL_GENDER: 'M',
          PERSONAL_PHOTO: '',
          CODE: 'Telegram'
        };
        botPhotoPreview.value = '';
        ElMessage({
          message: t('BitrixBotDialog.botRemoved'),
          type: 'success',
          duration: 3000,
        });
      } else {
        botEnabled.value = true;
        ElMessage({
          message: t('BitrixBotDialog.errorRemovingBot'),
          type: 'error',
          duration: 3000,
        });
      }
    }
  } catch (error) {
    console.error(error);
    botEnabled.value = !value; // Возвращаем предыдущее состояние при ошибке
    ElMessage({
      message: t('BitrixBotDialog.errorTogglingBot'),
      type: 'error',
      duration: 3000,
    });
  } finally {
    loading.value = false;
  }
};

// Обработка выбора фотографии
const handlePhotoChange = (file: { raw: File }) => {
  if (file && file.raw) {
    botPhotoFile.value = file.raw;
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target && e.target.result) {
        botPhotoPreview.value = e.target.result.toString();
        // Конвертируем фото в base64 для отправки на сервер
        botData.value.PERSONAL_PHOTO = e.target.result.toString();
      }
    };
    reader.readAsDataURL(file.raw);
  }
};

// Сохранение настроек бота
const save = async () => {
  loading.value = true;
  try {
    if (botEnabled.value) {
      if (botData.value.BOT_ID) {
        // Преобразуем ID в числовой формат, если это требуется
        if (typeof botData.value.BOT_ID === 'string') {
          botData.value.BOT_ID = parseInt(botData.value.BOT_ID, 10);
        }
        
        // Убедимся, что CODE установлен
        if (!botData.value.CODE) {
          botData.value.CODE = 'Telegram';
        }
        
        // Обновляем существующего бота
        const result = await updateBitrixBot(botData.value);
        
        if (result) {
          ElMessage({
            message: t('BitrixBotDialog.botUpdated'),
            type: 'success',
            duration: 3000,
          });
          close();
        } else {
          ElMessage({
            message: t('BitrixBotDialog.errorUpdatingBot'),
            type: 'error',
            duration: 3000,
          });
        }
      } else {
        // Создаем нового бота, если его нет
        const botSettings: Partial<BitrixBotData> = {
          ...botData.value,
          CODE: 'Telegram'
        };
        
        const createdBot = await registerBitrixBot(botSettings);
        
        if (createdBot) {
          ElMessage({
            message: t('BitrixBotDialog.botCreated'),
            type: 'success',
            duration: 3000,
          });
          close();
        } else {
          ElMessage({
            message: t('BitrixBotDialog.errorCreatingBot'),
            type: 'error',
            duration: 3000,
          });
        }
      }
    } else {
      // Просто закрываем диалог, если бот отключен
      close();
    }
  } catch (error) {
    console.error('Ошибка при сохранении настроек бота:', error);
    ElMessage({
      message: t('BitrixBotDialog.errorSavingSettings'),
      type: 'error',
      duration: 3000,
    });
  } finally {
    loading.value = false;
  }
};

// Наблюдение за состоянием модального окна в хранилище
const dialogOpen = computed(() => store.getters['getBitrixBotModal']);

// Закрытие диалога
const close = () => {
  store.commit('setBitrixBotModal', false);
};

// Загрузка данных при открытии диалога
const loadData = () => {
  checkBotStatus();
};

watch(dialogOpen, (newValue) => {
  modelValue.value = newValue;
  if (newValue) {
    loadData();
  }
});

watch(modelValue, (newValue) => {
  store.commit('setBitrixBotModal', newValue);
});
</script>

<style scoped>
.secondary {
  font-size: 16px;
}

.bot-settings {
  padding: 1rem 0;
}

.bot-photo-preview {
  margin-top: 10px;
  max-width: 100px;
  max-height: 100px;
}

.bot-photo-preview img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

h4 {
  font-size: 1.2rem;
  padding: 1rem 0rem;
  margin: 0;
  border-bottom: 1px solid #eaecef;
}

.el-radio-group {
  display: block;
  line-height: normal;
}
</style> 