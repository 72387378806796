<template>
  <div class="p-0-05"><el-divider /></div>
  <el-row v-for="(link) in linkList" :key="link.link" class="folder-row" @click="link.link">
    <el-col :span="23" class="pl-1 mh40 m0">
      <div class="m0">{{ t(link.title) }}</div>
      <div v-if="link.subtitle" class="secondary">{{ link.subtitle }}</div>
    </el-col>
    <el-col v-if="link.arrow" :span="1" class="arrow-right">
      <el-icon>
        <ArrowRightBold />
      </el-icon>
    </el-col>
  </el-row>
  <BotTokenDialog />
  <BitrixBotDialog />
</template>

<script setup lang="ts">
import { Link } from '@/models/link';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import BotTokenDialog from './BotTokenDialog.vue';
import BitrixBotDialog from './BitrixBotDialog.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const router = useRouter();
const store = useStore();
const linkList: Link[] = [
  {
    title: 'SettingsLinks.generalSettings',
    arrow: true,
    link: () => {
      router.push('/settings');
    },
  },
  {
    title: 'SettingsLinks.notificationTemplates',
    arrow: true,
    link: () => {
      router.push('/templates');
    },
  },
  {
    title: 'SettingsLinks.telegramBotSettings',
    arrow: false,
    link: () => {
      store.commit('setBotTokenModal', true);
    },
  },
  {
    title: 'SettingsLinks.bitrixBotSettings',
    arrow: false,
    link: () => {
      store.commit('setBitrixBotModal', true);
    },
  },
];
</script>

<style scoped></style>