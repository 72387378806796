import api from ".";
import { AxiosResponse } from "axios";
import { SettingList } from "@/models/settings";
import { Response } from "@/models/response";

export function getSettingList(): Promise<AxiosResponse<SettingList>> {
  return api({
    url: "/v1/setting",
    method: "get",
  });
}

export function updateSetting(f: SettingList): Promise<AxiosResponse<Response>> {
  return api({
    url: "/v1/setting",
    method: "put",
    data: f,
  });
}
