<template >
  <div class="card">
    <div class="card-title">
      <div>
        <slot name="title" />
        <slot name="header" />
      </div>
      <slot name="header-right" />
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    back: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>