import { Folder, FolderList } from "@/models/folder";
import api from ".";
import { AxiosResponse } from "axios";

export function getFolderList(offset?: number): Promise<AxiosResponse<FolderList>> {
  return api({
    url: "/v1/folder?limit=10&offset=" + (offset || 0),
    method: "get",
  });
}

export function addFolder(f: Folder): Promise<AxiosResponse<Folder>> {
  return api({
    url: "/v1/folder",
    method: "post",
    data: f,
  });
}

export function getFolder(id?: string): Promise<AxiosResponse<Folder>> {
  return api({
    url: "/v1/folder/" + id,
    method: "get",
  });
}

export function updateFolder(f?: Folder): Promise<AxiosResponse<Folder>> {
  return api({
    url: "/v1/folder/" + (f?.id || 0),
    method: "put",
    data: f,
  });
}

export function deleteFolder(f?: Folder): Promise<AxiosResponse<Folder>> {
  return api({
    url: "/v1/folder/" + (f?.id || 0),
    method: "delete",
  });
}