<template>
  <div class="p-0-05"><el-divider /></div>
  <div v-loading="loading">
    <el-row v-for="(folder) in folders" :key="folder.id" class="folder-row" @click="navigate(folder.id)">
      <el-col :span="2">
        <el-avatar icon="folder" :size="50" />
      </el-col>
      <el-col :span="21" class="pl-1">
        <div>{{ folder.uf_name }}</div>
        <div class="secondary">
          {{ t('FolderList.chats') }}: {{ folder.uf_count_chats ?? 0 }}
          <span v-if="folder.uf_count_errors" class="secondary">
            &nbsp;• <span>{{ t('FolderList.errors') }}: {{ folder.uf_count_errors ?? 0 }}</span>
          </span>
        </div>
      </el-col>
      <el-col :span="1" class="arrow-right">
        <el-icon>
          <ArrowRightBold />
        </el-icon>
      </el-col>
    </el-row>
    <el-row v-if="totalItemsCount > folders.length" class="folder-row" @click="showMore()">
      <el-col :span="2">
        <el-avatar icon="arrow-down" :size="50" class="link" />
      </el-col>
      <el-col :span="22" class="m0 link pl-1">
        <div>{{ t('FolderList.showMore') }}</div>
      </el-col>
    </el-row>
    <el-row class="folder-row" @click="newFolder()">
      <el-col :span="2">
        <el-avatar icon="plus" :size="50" class="link" />
      </el-col>
      <el-col :span="22" class="m0 link pl-1">
        <div>{{ t('FolderList.createFolder') }}</div>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { getFolderList } from '@/api/folders';
import { AxiosResponse } from 'axios';
import { Folder, FolderList } from '@/models/folder';
import { ElMessage } from 'element-plus';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  async setup() {
    const { t } = useI18n();
    const router = useRouter();
    const loading = ref(false);
    const folders = ref<Folder[]>([]);
    const totalItemsCount = ref(0);

    const loadData = async (offset?: number) => {
      loading.value = true;
      await getFolderList(offset)
        .then((response: AxiosResponse<FolderList>) => {
          folders.value = [...folders.value, ...response.data.items];
          totalItemsCount.value = response.data.total_items_count;
          loading.value = false;
        })
        .catch((error) => {
          console.error(error);
          ElMessage({
            message: t('FolderList.errorFetchingFolders'),
            type: 'error',
            duration: 3000,
          });
        });
    };

    onMounted(async () => {
      loadData();
    });

    return {
      folders, t,
      totalItemsCount,
      loading,
      navigate(folderId: number) {
        router.push('/folder/' + folderId);
      },
      newFolder() {
        router.push('/folder/new');
      },
      async showMore() {
        loadData(folders.value.length);
      },
    };
  },
});
</script>

<style scoped></style>