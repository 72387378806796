import api from ".";
import { AxiosResponse } from "axios";
import { Domain } from "@/models/domain";
import { Status } from "@/models/status";

export function updateDomain(
  f: Domain
): Promise<AxiosResponse<Status>> {
  return api({
    url: "/v1/domain",
    method: "put",
    data: f,
  });
}
