<template>
  <el-dialog v-model="modelValue" :title="t('ExitDialog.title')" width="500" align-center>
    <span>{{ t('ExitDialog.confirmationText') }}</span>
    <template #footer>
      <div class="dialog-footer pt-1">
        <el-button link @click="modelValue = false">{{ t('ExitDialog.continueEditing') }}</el-button>
        <el-button link @click="fn">{{ t('ExitDialog.exitWithoutSaving') }}</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { defineModel } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const modelValue = defineModel();
const fn = defineModel('fn');
const router = useRouter();

if (fn.value === undefined) {
  fn.value = () => router.back();
}
</script>

<style scoped></style>