import { AuthResponse, Bitrix24UserData, login, loginWithBitrix24 } from "@/api/auth"
import { AxiosResponse } from "axios"
import { useRouter } from "vue-router"
import { GetterTree, MutationTree, ActionTree } from "vuex"
import { errText } from ".."
import { Domain, JwtJson } from "@/models/domain"
import { updateDomain } from "@/api/domain"
const TOKEN_KEY = "jwt-token"
const ERROR_KEY = "domain-error"
const LOCALE = "locale"

class State {
  token: string | null = localStorage.getItem(TOKEN_KEY)
  error = Number(localStorage.getItem(ERROR_KEY))
  locale: string | null = localStorage.getItem(LOCALE)
  domain: Domain = {
    id: 0,
    uf_member_id: "",
    uf_domain: "",
    uf_license: "",
    uf_bot_id: 0,
    uf_command_id: 0,
    uf_error: false,
    uf_error_code: 0,
    uf_locale: "",
  }
}

export default {
  namespaced: true,
  state: new State(),
  mutations: <MutationTree<State>>{
    setToken(state, token: string): void {
      state.token = token;
      localStorage.setItem(TOKEN_KEY, token);
    },
    setDomainError(state, error: number): void {
      state.error = error;
      localStorage.setItem(ERROR_KEY, error.toString());
    },
    setLocale(state, locale: string): void {
      state.locale = locale;
      state.domain.uf_locale = locale;
      localStorage.setItem(LOCALE, locale);
    },
    setDomain(state, domain: Domain): void {
      state.domain = domain;
    },
    logout(state): void {
      state.token = null;
      localStorage.removeItem(TOKEN_KEY);
      state.error = 0;
      localStorage.removeItem(ERROR_KEY);
    },
  },
  actions: <ActionTree<State, unknown>>{
    // Метод login больше не используется, так как авторизация 
    // происходит напрямую в компоненте AuthPage
    // с использованием метода loginWithBitrix24
    
    async logout({ commit }) {
      const router = useRouter();
      commit("logout");
      router.push("/");
    },
    changeLocale({ commit, state }, locale: string) {
      commit("setLocale", locale)
      updateDomain(state.domain)
    },
  },
  getters: <GetterTree<State, unknown>>{
    token(state): string | null {
      return state.token;
    },
    error(state): string {
      return errText(state.error);
    },
    locale(state): string | null {
      return state.locale;
    },
    domain(state): Domain {
      return state.domain;
    },
    isAuthenticated(_, getters): boolean {
      return !!getters.token;
    },
  },
};
